import React, {useContext} from 'react';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import clsx from 'clsx';
import auth from "../../helpers/auth";
import {UserContext} from "../../providers/userProvider";

const AppNav = ({ open, handleDrawerOpen, classes }) => {
  return (
    <AppBar
      position='fixed'
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          onClick={handleDrawerOpen}
          edge='start'
          className={clsx(classes.menuButton, {
            [classes.hide]: open,
          })}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component='h1'
          variant='h6'
          style={{
            flexGrow: 1,
            textAlign: 'center',
          }}
          color='inherit'
          noWrap
        >
          TRANSPORTAIR ADMIN
        </Typography>
        {auth.getUser().email}
        <IconButton color='inherit' onClick={() => auth.getOut()}>
          <ExitToAppIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default AppNav;
