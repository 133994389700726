import React, {useEffect, useState} from 'react';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField/TextField";
import ReactImageTooltip from "react-image-tooltip";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/core/SvgIcon/SvgIcon";
import {makeStyles} from "@material-ui/core/styles";
import firebase from "../../Firebase";
import Alert from "@material-ui/lab/Alert/Alert";
import Avatar from "@material-ui/core/Avatar";
import Snackbar from "@material-ui/core/Snackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '50px 50px',
    width: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
}));

const ref = firebase.firestore().collection('admin').doc("order");

const ConfigCommand = () => {
  const classes = useStyles();
  const [state, setState] = useState({
    error: null,
    info: null,
    order: {},
  });

  useEffect(() => {
    start();
    return () => {
    };
  }, []);

  const clear = () => {
    setState(state => ({...state, info: null, error: null}));
  };

  const start = () => {
    ref.get().then((doc) => {
      if (doc.exists) {
        let order = doc.data();
        setState(state => ({...state, order}));
      }
    });
  };

  const handleChange = (event, field) => {
    setState(state => ({...state, order: {...state.order, [field]: event.target.value}}))
  };

  const update = () => {
    ref.set(state.order).then(() => {
      let info = "Enregistré avec succès";
      setState(state => ({...state, info}));
      return true;
    })
      .catch((error) => {
        setState(state => ({...state, error}));
      });
  };

  return (
    <div className={classes.root}>
      {state.info && <Snackbar open={Boolean(state.info)} autoHideDuration={6000} onClose={clear}>
        <Alert onClose={clear} variant="filled" severity="success">
          {state.info}
        </Alert>
      </Snackbar>}
      {state.error && <Snackbar open={Boolean(state.error)} autoHideDuration={10000} onClose={clear}>
        <Alert onClose={clear} variant="filled" severity="error">
          {state.error}
        </Alert>
      </Snackbar>}
      <Box mb={3}>
        <Typography color="textPrimary" variant="h2">
          Command
        </Typography>
        <Typography color="textSecondary" gutterBottom variant="body2">
          Configuration
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="outlined-full-width"
            label="Titre"
            style={{margin: 8}}
            value={state.order.title || ''}
            onChange={(event) => handleChange(event, 'title')}
            placeholder="Titre"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
          <TextField
            id="outlined-full-width"
            label="Description"
            style={{margin: 8}}
            placeholder="Description"
            value={state.order.desc || ''}
            onChange={(event) => handleChange(event, 'desc')}
            fullWidth
            multiline
            rows={4}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        {/* 1 */}

        <Grid item xs={6}>
          <TextField
            id="outlined-full-width"
            label="LOGO 1"
            style={{margin: 8}}
            value={state.order.providerLogo1 || ''}
            onChange={(event) => handleChange(event, 'providerLogo1')}
            placeholder="LOGO 1"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-full-width"
            label="URL 1"
            style={{margin: 8}}
            placeholder="URL 1"
            value={state.order.providerUrl1 || ''}
            onChange={(event) => handleChange(event, 'providerUrl1')}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        {/* 2 */}
        <Grid item xs={6}>
          <TextField
            id="outlined-full-width"
            label="LOGO 2"
            style={{margin: 8}}
            value={state.order.providerLogo2 || ''}
            onChange={(event) => handleChange(event, 'providerLogo2')}
            onMouseEnter={() => console.log("enter")} onMouseLeave={() => console.log("out")}
            placeholder="LOGO 2"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-full-width"
            label="URL 2"
            style={{margin: 8}}
            value={state.order.providerUrl2 || ''}
            onChange={(event) => handleChange(event, 'providerUrl2')}
            placeholder="URL 2"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        {/* 3 */}
        <Grid item xs={6}>
          <TextField
            id="outlined-full-width"
            label="LOGO 3"
            style={{margin: 8}}
            value={state.order.providerLogo3 || ''}
            onChange={(event) => handleChange(event, 'providerLogo3')}
            placeholder="LOGO 3"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-full-width"
            label="URL 3"
            style={{margin: 8}}
            value={state.order.providerUrl3 || ''}
            onChange={(event) => handleChange(event, 'providerUrl3')}
            placeholder="URL 3"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        {/* 4 */}
        <Grid item xs={6}>
          <TextField
            id="outlined-full-width"
            label="LOGO 4"
            style={{margin: 8}}
            placeholder="LOGO 4"
            value={state.order.providerLogo4 || ''}
            onChange={(event) => handleChange(event, 'providerLogo4')}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-full-width"
            label="URL 4"
            style={{margin: 8}}
            value={state.order.providerUrl4 || ''}
            onChange={(event) => handleChange(event, 'providerUrl4')}
            placeholder="URL 4"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        {/* 5 */}
        <Grid item xs={6}>
          <TextField
            id="outlined-full-width"
            label="LOGO 5"
            style={{margin: 8}}
            placeholder="LOGO 5"
            value={state.order.providerLogo5 || ''}
            onChange={(event) => handleChange(event, 'providerLogo5')}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-full-width"
            label="URL 5"
            style={{margin: 8}}
            placeholder="URL 5"
            fullWidth
            value={state.order.providerUrl5 || ''}
            onChange={(event) => handleChange(event, 'providerUrl5')}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        {/* 6 */}
        <Grid item xs={6}>
          <TextField
            id="outlined-full-width"
            label="LOGO 6"
            style={{margin: 8}}
            value={state.order.providerLogo6 || ''}
            onChange={(event) => handleChange(event, 'providerLogo6')}
            placeholder="LOGO 6"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-full-width"
            label="URL 6"
            style={{margin: 8}}
            placeholder="URL 6"
            value={state.order.providerUrl6 || ''}
            onChange={(event) => handleChange(event, 'providerLogo6')}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        {/* 7 */}
        <Grid item xs={6}>
          <TextField
            id="outlined-full-width"
            label="LOGO 7"
            style={{margin: 8}}
            placeholder="LOGO 7"
            value={state.order.providerLogo7 || ''}
            onChange={(event) => handleChange(event, 'providerLogo7')}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-full-width"
            label="URL 7"
            style={{margin: 8}}
            placeholder="URL 7"
            value={state.order.providerUrl7 || ''}
            onChange={(event) => handleChange(event, 'providerUrl7')}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        {/* 8 */}
        <Grid item xs={6}>
          <TextField
            id="outlined-full-width"
            label="LOGO 8"
            style={{margin: 8}}
            placeholder="LOGO 8"
            value={state.order.providerLogo8 || ''}
            onChange={(event) => handleChange(event, 'providerLogo8')}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-full-width"
            label="URL 8"
            value={state.order.providerUrl8 || ''}
            onChange={(event) => handleChange(event, 'providerUrl8')}
            style={{margin: 8}}
            placeholder="URL 8"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        {/* 9 */}
        <Grid item xs={6}>
          <TextField
            id="outlined-full-width"
            label="LOGO 9"
            style={{margin: 8}}
            placeholder="LOGO 9"
            value={state.order.providerLogo9 || ''}
            onChange={(event) => handleChange(event, 'providerLogo9')}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-full-width"
            label="URL 9"
            style={{margin: 8}}
            placeholder="URL 9"
            value={state.order.providerUrl9 || ''}
            onChange={(event) => handleChange(event, 'providerUrl9')}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        {/* 10 */}
        <Grid item xs={6}>
          <TextField
            id="outlined-full-width"
            label="LOGO 10"
            style={{margin: 8}}
            placeholder="LOGO 10"
            value={state.order.providerLogo10 || ''}
            onChange={(event) => handleChange(event, 'providerLogo10')}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-full-width"
            label="URL 10"
            style={{margin: 8}}
            placeholder="URL 10"
            value={state.order.providerUrl10 || ''}
            onChange={(event) => handleChange(event, 'providerUrl10')}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        {/* 11 */}
        <Grid item xs={6}>
          <TextField
            id="outlined-full-width"
            label="LOGO 11"
            style={{margin: 8}}
            placeholder="LOGO 11"
            value={state.order.providerLogo11 || ''}
            onChange={(event) => handleChange(event, 'providerLogo11')}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-full-width"
            label="URL 11"
            style={{margin: 8}}
            placeholder="URL 11"
            value={state.order.providerUrl11 || ''}
            onChange={(event) => handleChange(event, 'providerUrl11')}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        {/* 12 */}
        <Grid item xs={6}>
          <TextField
            id="outlined-full-width"
            label="LOGO 12"
            style={{margin: 8}}
            placeholder="LOGO 12"
            value={state.order.providerLogo12 || ''}
            onChange={(event) => handleChange(event, 'providerLogo12')}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-full-width"
            label="URL 12"
            style={{margin: 8}}
            placeholder="URL 12"
            value={state.order.providerUrl12 || ''}
            onChange={(event) => handleChange(event, 'providerUrl12')}
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Button
        variant="contained"
        style={{backgroundColor: 'green', color: 'white'}}
        size="large"
        onClick={() => update()}
      >
        Modifier
      </Button>
    </div>
  );
};

export default ConfigCommand;
