import React, {useContext, useEffect, useState} from 'react';
import {Route, BrowserRouter as Router, Switch} from 'react-router-dom';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import './App.css';
import AppNav from "./components/layouts/NavBar";
import AppDrawer from "./components/layouts/Drawer";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import Config from "./components/Config";
import ConfigCommand from "./components/ConfigCommand";
import Message from "./components/Message";
import MiseAJourColis from "./components/MiseAJour";
import {ConfirmProvider} from "material-ui-confirm";
import ShowMessage from "./components/Message/ShowMessage";
import UserProvider, {UserContext} from "./providers/userProvider";
import auth from "./helpers/auth";
import Itineraire from "./components/MiseAJour/Itineraire";
import TypeExpedition from "./components/TypeExpedition";
import ShowTypeExpedition from "./components/TypeExpedition/ShowTypeExpedition";

function App() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [user, setUser] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged(setUser);
  }, []);

  return (
    <UserProvider>
      <ConfirmProvider>
        <Router>
          {user ? <div className={classes.root}>
            <CssBaseline/>
            <AppNav
              open={open}
              handleDrawerOpen={handleDrawerOpen}
              classes={classes}
            />
            <AppDrawer
              classes={classes}
              open={open}
              handleDrawerClose={handleDrawerClose}
              theme={theme}
            />
            <Switch>
              <Route exact path='/' component={Dashboard}/>
              <Route exact path='/config' component={Config}/>
              <Route exact path='/oderconfig' component={ConfigCommand}/>
              <Route exact path='/messages' component={Message}/>
              <Route exact path='/show-message/:id' component={ShowMessage}/>
              <Route exact path='/mise-a-jour-colis' component={MiseAJourColis}/>
              <Route exact path='/itineraire/:code' component={Itineraire}/>
              <Route exact path='/type-expeditions' component={TypeExpedition}/>
              <Route exact path='/show-type-expedition/:id' component={ShowTypeExpedition}/>
            </Switch>
          </div> : <Login/>}
        </Router>
      </ConfirmProvider>
    </UserProvider>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  title: {
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
}));


export default App;
