import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

//const settings = {timestampsInSnapshots: true};

const firebaseConfig = {
  apiKey: "AIzaSyCKfKWQQf9yv02PAZZHSOyHvXzHQ5jLOKI",
  authDomain: "trans-port-air.firebaseapp.com",
  databaseURL: "https://trans-port-air.firebaseio.com",
  projectId: "trans-port-air",
  storageBucket: "trans-port-air.appspot.com",
  messagingSenderId: "922850973079",
  appId: "1:922850973079:web:a840c2bd33f762f441e0d2",
  measurementId: "G-F01M0RWHTN"
};

firebase.initializeApp(firebaseConfig);

//firebase.firestore().settings(settings);

export default firebase;