import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box/Box";
import Typography from "@material-ui/core/Typography/Typography";
import {ConfirmProvider, useConfirm} from 'material-ui-confirm';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import MUIDataTable, {TableFilterList} from "mui-datatables";
import ButtonGroup from "@material-ui/core/ButtonGroup/ButtonGroup";
import Button from "@material-ui/core/Button/Button";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import firebase from "../../Firebase";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import Alert from "@material-ui/lab/Alert/Alert";


const ref = firebase.firestore().collection('messages');

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '50px 50px',
    width: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  fw: {
    fontWeight: 'bold',
  }
}));

const Messages = (props) => {
  const confirm = useConfirm();

  const classes = useStyles();
  const [state, setState] = useState({
    info: null,
    messages: [],
  });

  const columns = [
    {
      name: "Date",
      options: {
        filter: true,
        setCellProps: (row, dataIndex, rowIndex) => !state.messages[dataIndex][7] ? ({className: classes.fw}) : {},
      }
    },
    {
      name: "Objet",
      options: {
        filter: true,
        setCellProps: (row, dataIndex, rowIndex) => !state.messages[dataIndex][7] ? ({className: classes.fw}) : {},
      }
    },
    {
      name: "Nom Prenoms",
      options: {
        filter: true,
        setCellProps: (row, dataIndex, rowIndex) => !state.messages[dataIndex][7] ? ({className: classes.fw}) : {},
      }
    },
    {
      name: "Telephone",
      options: {
        filter: true,
        setCellProps: (row, dataIndex, rowIndex) => !state.messages[dataIndex][7] ? ({className: classes.fw}) : {},
      }
    },
    {
      name: "Email",
      options: {
        filter: true,
        setCellProps: (row, dataIndex, rowIndex) => !state.messages[dataIndex][7] ? ({className: classes.fw}) : {},
      }
    },
    {
      name: "Message",
      options: {
        filter: true,
        setCellProps: (row, dataIndex, rowIndex) => !state.messages[dataIndex][7] ? ({className: classes.fw}) : {},
      }
    },
    {
      name: "Action",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <ButtonGroup size="small" color="primary" aria-label="small outlined button group">
              <Button onClick={() => goToDetail(value)}><VisibilityIcon/></Button>
              <Button color="secondary" onClick={() => deleteMsg(value)}><DeleteIcon/></Button>
            </ButtonGroup>
          );
        }
      }
    }
  ];

  const options = {
    responsive: 'standard',
    selectableRows: 'none',
    viewColumns: false,
    filter: false,
  };

  const loadData = () => {
    let messages = [];
    ref.orderBy("date", "desc").get().then((querySnapshot) => {
      querySnapshot.forEach((document) => {
        let message = [];
        let data = document.data();
        message.push(data.date);
        message.push(data.objet);
        message.push(data.name + " " + data.firstname);
        message.push(data.phone);
        message.push(data.email);
        message.push(data.message?.toString().substr(0, 60) + "...");
        message.push(document.id);
        message.push(data.lu);
        messages.push(message);
      });
      setState(state => ({...state, messages}));
    });
  };

  useEffect(() => {
    loadData();
    return () => {
    };
  }, []);

  const render = () => {
    if (!state.messages.length) {
      return <div style={{position: 'absolute', top: '50%', left: '50%'}}>
        <CircularProgress/>
      </div>;
    } else {
      return <MUIDataTable data={state.messages} columns={columns} options={options}/>
    }
  };

  const clear = () => {
    setTimeout(() => {
      setState(state => ({...state, info: null}));
    }, 4000);
  };

  const goToDetail = async (id) => {
    await ref.doc(id).update({
      lu: 1
    });
    props.history.push("/show-message/" + id);
  };

  const deleteMsg = (id) => {
    confirm({title: 'Etes vous sure ?', description: 'Voulez vous vraiment supprimer ?'})
      .then(() => {
        ref.doc(id).delete().then(() => {
          loadData();
          let info = "Message supprimé avec succès";
          setState(state => ({...state, info}));
        });
      })
      .catch(() => {
        console.log("sss")
      });
  };

  return (
    <div className={classes.root}>
      {state.info && <Snackbar open={Boolean(state.info)} autoHideDuration={6000} onClose={() => clear()}>
        <Alert onClose={() => clear()} variant="filled" severity="success">
          {state.info}
        </Alert>
      </Snackbar>}
      <Box mb={3}>
        <Typography color="textPrimary" variant="h2">
          Messages
        </Typography>
        <Typography color="textSecondary" variant="body2">
          Liste des messages reçus
        </Typography>
      </Box>
      {render()}
    </div>
  );
};

export default Messages;
