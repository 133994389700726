import React from 'react';
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { useLocation } from 'react-router-dom'

const MenuListItem = ({to, Component, text}) => {
    const location = useLocation();

    return (
        <ListItem button selected={to === location.pathname}>
            <ListItemIcon>
                {Component}
            </ListItemIcon>
            <ListItemText primary={text} style={{fontSize:'0.7em'}}/>
        </ListItem>
    );
};

export default MenuListItem;
