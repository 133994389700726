import { createMuiTheme }  from '@material-ui/core/styles';
import {blue, green} from "@material-ui/core/colors";

const theme = createMuiTheme({
  palette: {
    primary: blue,
    sre: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    tertiary: {
      light: '#00e676',
      main: '#00a152',
      dark: '#33eb91',
      contrastText: '#fff',
    },
  },
});

export default theme