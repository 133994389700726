import React, {useEffect, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import firebase from "../../Firebase";
import Container from "@material-ui/core/Container";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const ref = firebase.firestore().collection('admin-expedition-prices');

const ShowTypeExpedition = (props) => {

  const [state, setState] = useState({
    typeExpedition: {
      id: '',
      libelle: '',
      description: '',
      moyenExpedition: {
        airWay: {
          checked: true,
          prixRefPoids: 0,
          prixRefVol: 0,
          prixRefVal: 0,
          prixRefMin: 0,
        },
        groundRoute: {
          checked: false,
          prixRefPoids: 0,
          prixRefVol: 0,
          prixRefVal: 0,
          prixRefMin: 0,
        },
        seaRoute: {
          checked: false,
          prixRefPoids: 0,
          prixRefVol: 0,
          prixRefVal: 0,
          prixRefMin: 0,
        },
        contactUs: {
          checked: false,
        },
      }
    }
  });

  const getTypeExpedition = () => {
    ref.doc(props.match.params.id).get()
      .then(doc => {
        if (doc.exists) {
          let typeExpedition = doc.data();
          typeExpedition.id = doc.id;
          console.log(typeExpedition);
          setState(state => ({...state, typeExpedition: typeExpedition}));
        }
      }).catch(err => {
      console.log(err);
    });
  };

  const handleChange = (event, field) => {
    setState(state => ({...state, typeExpedition: {...state.typeExpedition, [field]: event.target.value}}));
  };

  const handleChange1 = (event, fields, checkbox=false) => {
    state.typeExpedition.moyenExpedition[fields[0]][fields[1]] = checkbox ? event.target.checked : event.target.value;
    setState(state => ({...state}));
  };

  const save = () => {
    let nR = props.match.params.id.toString() === 'new' ? ref.doc() : ref.doc(props.match.params.id);
    nR.set(state.typeExpedition)
      .then(r => {
        props.history.push("/type-expeditions");
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getText = () => {
    return props.match.params.id.toString() === 'new' ? 'Ajouter' : 'Modifier'
  };

  useEffect(() => {
    getTypeExpedition();
    return () => {
    };
  }, []);


  return (
    <div className="root">
      <Box mb={3}>
        <Typography color="textPrimary" variant="h2">
          {getText()}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          Type d'expedition
        </Typography>
      </Box>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="outlined-full-width"
              label="Libellé"
              style={{margin: 8}}
              value={state.typeExpedition.libelle || ''}
              onChange={(event) => handleChange(event, 'libelle')}
              placeholder="Libellé"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
            <TextField
              id="outlined-full-width"
              label="Description"
              style={{margin: 8}}
              placeholder="Description"
              value={state.typeExpedition.description || ''}
              onChange={(event) => handleChange(event, 'description')}
              fullWidth
              multiline
              rows={4}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <br/><br/>
        <Typography variant='h6'>
          MOYEN D'EXPÉDITION
        </Typography>
        <br/>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.typeExpedition.moyenExpedition.airWay.checked || false}
                  onChange={(event) => handleChange1(event, ['airWay', 'checked'], true)}
                  name="airway"
                  color="primary"
                />
              }
              label="Voie aérienne"
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              label="Tarif par poids"
              id="airWay.prixRefPoids"
              value={state.typeExpedition.moyenExpedition.airWay.prixRefPoids || 0}
              onChange={(event) => handleChange1(event, ['airWay', 'prixRefPoids'])}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              label="Tarif par volume"
              id="airWay.prixRefVolume"
              value={state.typeExpedition.moyenExpedition.airWay.prixRefVol || 0}
              onChange={(event) => handleChange1(event, ['airWay', 'prixRefVol'])}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="airWay.prixRefVal"
              label="Tarif par valeur"
              value={state.typeExpedition.moyenExpedition.airWay.prixRefVal || 0}
              onChange={(event) => handleChange1(event, ['airWay', 'prixRefVal'])}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              label="Tarif minimum"
              id="airWay.prixRefMin"
              value={state.typeExpedition.moyenExpedition.airWay.prixRefMin || 0}
              onChange={(event) => handleChange1(event, ['airWay', 'prixRefMin'])}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <br/><br/>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.typeExpedition.moyenExpedition.seaRoute.checked || false}
                  onChange={(event) => handleChange1(event, ['seaRoute', 'checked'], true)}
                  name="seaRoute"
                  color="primary"
                />
              }
              label="Voie Maritime"
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              label="Tarif par poids"
              id="seaRoute.prixRefPoids"
              value={state.typeExpedition.moyenExpedition.seaRoute.prixRefPoids || 0}
              onChange={(event) => handleChange1(event, ['seaRoute', 'prixRefPoids'])}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              label="Tarif par volume"
              id="seaRoute.prixRefVolume"
              value={state.typeExpedition.moyenExpedition.seaRoute.prixRefVol || 0}
              onChange={(event) => handleChange1(event, ['seaRoute', 'prixRefVol'])}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="seaRoute.prixRefVal"
              label="Tarif par valeur"
              value={state.typeExpedition.moyenExpedition.seaRoute.prixRefVal || 0}
              onChange={(event) => handleChange1(event, ['seaRoute', 'prixRefVal'])}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              label="Tarif minimum"
              id="seaRoute.prixRefMin"
              value={state.typeExpedition.moyenExpedition.seaRoute.prixRefMin || 0}
              onChange={(event) => handleChange1(event, ['seaRoute', 'prixRefMin'])}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <br/><br/>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.typeExpedition.moyenExpedition.groundRoute.checked || false}
                  onChange={(event) => handleChange1(event, ['groundRoute', 'checked'], true)}
                  name="groundRoute"
                  color="primary"
                />
              }
              label="Voie Terrestre"
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              label="Tarif par poids"
              id="groundRoute.prixRefPoids"
              value={state.typeExpedition.moyenExpedition.groundRoute.prixRefPoids || 0}
              onChange={(event) => handleChange1(event, ['groundRoute', 'prixRefPoids'])}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              label="Tarif par volume"
              id="groundRoute.prixRefVolume"
              value={state.typeExpedition.moyenExpedition.groundRoute.prixRefVol || 0}
              onChange={(event) => handleChange1(event, ['groundRoute', 'prixRefVol'])}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="groundRoute.prixRefVal"
              label="Tarif par valeur"
              value={state.typeExpedition.moyenExpedition.groundRoute.prixRefVal || 0}
              onChange={(event) => handleChange1(event, ['groundRoute', 'prixRefVal'])}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              label="Tarif minimum"
              id="groundRoute.prixRefMin"
              value={state.typeExpedition.moyenExpedition.groundRoute.prixRefMin || 0}
              onChange={(event) => handleChange1(event, ['groundRoute', 'prixRefMin'])}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <br/><br/>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.typeExpedition.moyenExpedition.contactUs?.checked || false}
                  onChange={(event) => handleChange1(event, ['contactUs', 'checked'], true)}
                  name="contactUs"
                  color="primary"
                />
              }
              label="Utilisateur doit nous contater"
            />
          </Grid>
        </Grid>
        <Button style={{marginTop: '40px', marginRight: '40px'}} onClick={save} variant="contained" color="primary">
          {getText()}
        </Button>
        <Button style={{marginTop: '40px'}} variant="contained" color="secondary" onClick={() => props.history.push('/type-expeditions')}>
          Anuller
        </Button>
      </Container>
    </div>
  );
};

export default ShowTypeExpedition;
