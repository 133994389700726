import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  colors
} from '@material-ui/core';
import Box from "@material-ui/core/Box/Box";

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.indigo[600],
    height: 56,
    width: 56
  }
}));

const DashboardItemPromo = ({name, nextPromo, link, Component}) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root)}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              {name}
              <Box m={1} p={1}>
              <Typography variant="h6">{nextPromo.dateDebutPromo}</Typography> <br/>
              <Typography variant="overline" display="block" gutterBottom>
                 Tarifs: {nextPromo.prixPoid}(poids) - {nextPromo.prixVolume}(volume)
              </Typography>
            </Box>
            <Box m={1} p={1}>
              <Typography variant="overline" display="block" gutterBottom>
                Fin le {nextPromo.dateFinPromo}
              </Typography>
            </Box>
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar} component={Link} to={link}>
              {Component}
            </Avatar>
          </Grid>
        </Grid>

      </CardContent>
    </Card>
  );
};

DashboardItemPromo.propTypes = {
  name: PropTypes.string,
  nextPromo: PropTypes.object,
  Component: PropTypes.object
};

export default DashboardItemPromo;