import firebase from "../Firebase";

export default {
  getIn: function (email, password) {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  },

  getOut: function () {
    this.destroySession();
    return firebase.auth().signOut();
  },

  getUser: function () {
    return firebase.auth().currentUser;
  },

  setSession: function (user, token) {
    sessionStorage.setItem("email", user.email);
    sessionStorage.setItem("logged", "true");
    sessionStorage.setItem("token", token);
  },

  destroySession: function () {
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("logged");
    sessionStorage.removeItem("token");
  },

  isUserConected: function () {
    return firebase.auth().currentUser !== null;
  },

  onAuthStateChanged: function (callBack) {
    firebase.auth().onAuthStateChanged((user) => {
      callBack(user)
    });
  }
}