import React, {useState} from 'react';
import {Link as RouterLink, withRouter} from 'react-router-dom';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import auth from "../../helpers/auth";
import backend from "../../helpers/backend";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    flexGrow: 1,
    margin: '100px',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Login = (props) => {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    email: '',
    password: '',
  });

  return (
    <div className={classes.root}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center">
        <Container maxWidth="sm">
          {error && <Alert severity="error">
            <AlertTitle>Erreur</AlertTitle>
            Message : <strong>{error}</strong>
          </Alert>}
          <br/><br/>
          <Formik
            initialValues={data}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Email non valide').max(255).required('Email est requis'),
              password: Yup.string().max(255).required('Le mot de passe est requis')
            })}
            onSubmit={(values, {setSubmitting}) => {
              auth.getIn(values.email, values.password)
                .then(value => {
                  let user = auth.getUser();
                  let token = `${user.email}-${user.uid}`;
                  token = btoa(token);
                  auth.setSession(user, token);
                  props.history.push("/");
                })
                .catch(error => {
                  setSubmitting(false);
                  setError(error.message);
                  console.log(error);
                });
              setSubmitting(true);
            }}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h4"
                  >
                    Login
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Connecter vous pour acceder
                  </Typography>
                </Box>
                <Box
                  mt={3}
                  mb={1}
                >
                  <Typography
                    align="center"
                    color="textSecondary"
                    variant="body1"
                  >
                    TRANS-PORT-AIR
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Addresse Email"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Mot de passe"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Connexion
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </div>
  );
};

export default withRouter(Login);
