import React, {useEffect, useState} from 'react';
import firebase from "../../Firebase";
import backend from "../../helpers/backend";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/styles";
import {Paper} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {scryRenderedComponentsWithType} from "react-dom/test-utils";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import Alert from "@material-ui/lab/Alert/Alert";

const reference = firebase.firestore().collection('expeditions');

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  fG: {
    flexGrow: 1,
  },
  nested: {
    padding: theme.spacing(1),
  },
}));

const Itineraire = (props) => {
  const classes = useStyles();
  const [state, setState] = useState({
    dialog: false,
    input_itineraire: "",
    itineraire: [],
    expedition: {},
    info: null,
    colis: null,
    listcolis: [],
    sender: {},
    receiver: {},
    etat_expedition: null
  });

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const clear = () => {
    setTimeout(() => {
      setState(state => ({...state, info: null}));
    }, 4000);
  };

  useEffect(() => {
    getExpedition();
    getItineraire();
    return () => {
    };
  }, []);

  const getExpedition = () => {
    reference.doc(props.match.params.code).get()
      .then(doc => {
        let expedition = doc.data();
        let listcolis = expedition.listcolis;
        let sender = expedition.sender;
        let receiver = expedition.receiver;
        setState(state => ({...state, expedition, listcolis, sender, receiver}));
        console.log("Expp", listcolis);
      }).catch(err => {
      console.log(err);
    });
  };

  const changeEtat = () => {
    let newEtat = state.expedition.etat === 'encours' ? 'terminé' : 'encours';
    state.expedition.etat = newEtat;
    reference.doc(props.match.params.code)
      .set({etat: newEtat}, {merge: true})
      .then(() => {
        let info = "Colis " + newEtat;
        setState(state => ({...state, info}));
      });
  };

  const goBack = () => {
    props.history.push("/mise-a-jour-colis");
  };

  const getItineraire = () => {
    reference.doc(props.match.params.code).collection("itineraires").orderBy("numero", "desc").onSnapshot((querySnapshot) => {
      let itineraire = [];
      querySnapshot.forEach((document) => {
        let etape = {};
        etape.numero = document.data().numero;
        etape.date = backend.myFormatDate(document.data().date.toDate());
        etape.valeur = document.data().valeur;
        itineraire.push(etape);
      });
      setState(state => ({...state, itineraire}));
    });
  };

  const addItineraire = () => {
    let date = new Date();
    let data = {
      date: date,
      valeur: state.input_itineraire,
      numero: state.itineraire.length + 1
    };
    reference.doc(props.match.params.code).collection("itineraires")
      .add(data)
      .then(() => {
        setState((state) => ({...state, input_itineraire: ""}));
      });
  };

  return (
    <div className="root">
      {state.info && <Snackbar open={Boolean(state.info)} autoHideDuration={6000} onClose={() => clear()}>
        <Alert onClose={() => clear()} variant="filled" severity="success">
          {state.info}
        </Alert>
      </Snackbar>}
      <AppBar position="static" color="default">
        <Toolbar>
          <Typography variant="h6" className={classes.fG} display="inline">Colis</Typography>
          <Typography variant="h5" color="primary" display="inline"
                      className={classes.fG}>{props.match.params.code}</Typography>
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            Itineraire...
          </Button>
          <Button variant="contained" color="secondary" onClick={() => changeEtat()}>
            {state.expedition.etat === 'encours' ? 'Terminer' : state.expedition.etat === 'terminé' ? 'Réouvir' : 'Commencer'}
          </Button>
          <Button variant="contained" color="inherit" onClick={goBack}>Retour</Button>
        </Toolbar>
      </AppBar>
      <br/>
      <Typography variant="h6" display="inline">Montant totale facture :</Typography>
      <Typography variant="h5" color="primary" display="inline">{state.expedition.totalPrice} Euro</Typography>
      <br/><br/>
      {Object.keys(state.listcolis).map((colis, index) => (
        <TableContainer component={Paper} key={colis}>
          <Typography variant="button" display="block" gutterBottom>
            {state.listcolis[index].shipping_type.libelle} : {state.listcolis[index].description}
          </Typography>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Dimension (cm x cm x cm)</TableCell>
                <TableCell align="right">Poids (kg)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  {state.listcolis[index].length} x {state.listcolis[index].width} x {state.listcolis[index].height}
                </TableCell>
                <TableCell align="right">{state.listcolis[index].weight}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ))}
      <br/><br/>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography variant="button" display="block" color="primary" gutterBottom>
            Expéditeur
          </Typography>
          <List style={{borderRight: '0.1em solid black', padding: '0.5em'}}>
            <ListItem dense button divider className={classes.nested}>
              <ListItemText primary="Nom"/>
              <ListItemSecondaryAction>
                {state.sender.firstName}
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem dense button divider className={classes.nested}>
              <ListItemText primary="Prénom"/>
              <ListItemSecondaryAction>
                {state.sender.lastName}
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem dense button divider className={classes.nested}>
              <ListItemText primary="Téléphone"/>
              <ListItemSecondaryAction>
                {state.sender.phone}
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem dense button divider className={classes.nested}>
              <ListItemText primary="E-mail"/>
              <ListItemSecondaryAction>
                <a href={'mailto:' + state.sender.email}>{state.sender.email}</a>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem dense button divider className={classes.nested}>
              <ListItemText primary="Adresse"/>
              <ListItemSecondaryAction>
                {state.sender.address}
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem dense button divider className={classes.nested}>
              <ListItemText primary="Numéro de rue"/>
              <ListItemSecondaryAction>
                {state.sender.street_number}
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem dense button divider className={classes.nested}>
              <ListItemText primary="Route"/>
              <ListItemSecondaryAction>
                {state.sender.route}
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem dense button divider className={classes.nested}>
              <ListItemText primary="Code postal"/>
              <ListItemSecondaryAction>
                {state.sender.postal_code}
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem dense button divider className={classes.nested}>
              <ListItemText primary="Ville"/>
              <ListItemSecondaryAction>
                {state.sender.city}
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem dense button divider className={classes.nested}>
              <ListItemText primary="Région"/>
              <ListItemSecondaryAction>
                {state.sender.administrative_area_level_1}
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem dense button divider className={classes.nested}>
              <ListItemText primary="Pays"/>
              <ListItemSecondaryAction>
                {state.sender.country}
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="button" display="block" color="primary" gutterBottom>
            Destinataire
          </Typography>
          <List>
            <ListItem dense button divider className={classes.nested}>
              <ListItemText primary="Nom"/>
              <ListItemSecondaryAction>
                {state.receiver.firstName}
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem dense button divider className={classes.nested}>
              <ListItemText primary="Prénom"/>
              <ListItemSecondaryAction>
                {state.receiver.lastName}
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem dense button divider className={classes.nested}>
              <ListItemText primary="Téléphone"/>
              <ListItemSecondaryAction>
                {state.receiver.phone}
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem dense button divider className={classes.nested}>
              <ListItemText primary="E-mail"/>
              <ListItemSecondaryAction>
                <a href={'mailto:' + state.receiver.email}>{state.receiver.email}</a>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem dense button divider className={classes.nested}>
              <ListItemText primary="Adresse"/>
              <ListItemSecondaryAction>
                {state.receiver.address}
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem dense button divider className={classes.nested}>
              <ListItemText primary="Numéro de rue"/>
              <ListItemSecondaryAction>
                {state.receiver.street_number}
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem dense button divider className={classes.nested}>
              <ListItemText primary="Route"/>
              <ListItemSecondaryAction>
                {state.receiver.route}
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem dense button divider className={classes.nested}>
              <ListItemText primary="Code postal"/>
              <ListItemSecondaryAction>
                {state.receiver.postal_code}
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem dense button divider className={classes.nested}>
              <ListItemText primary="Ville"/>
              <ListItemSecondaryAction>
                {state.receiver.city}
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem dense button divider className={classes.nested}>
              <ListItemText primary="Région"/>
              <ListItemSecondaryAction>
                {state.receiver.administrative_area_level_1}
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem dense button divider className={classes.nested}>
              <ListItemText primary="Pays"/>
              <ListItemSecondaryAction>
                {state.receiver.country}
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={true} scroll="paper"
              aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Itinéraire colis&nbsp;{props.match.params.code}</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Typography variant="button" display="block" gutterBottom>
            </Typography>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Date de traitement</TableCell>
                  <TableCell align="right" style={{width: 320}}> </TableCell>
                  <TableCell align="justify">Point de transit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.itineraire.map((etape) => (
                  <TableRow key={etape.date}>
                    <TableCell component="th" scope="row">
                      {etape.date}
                    </TableCell>
                    <TableCell align="right" style={{width: 320}}> </TableCell>
                    <TableCell align="justify">{etape.valeur}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <DialogContentText>
            <br/><br/>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            value={state.input_itineraire}
            onChange={(e) => setState((state) => ({...state, input_itineraire: e.target.value}))}
            id="transit"
            label="Nouveau point de transit"
            type="text"
            fullWidth
          /> <br/>
          <Button onClick={addItineraire} color="primary" disabled={Boolean(state.input_itineraire === "")}
                  variant="contained">
            Ajouter
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Itineraire;
