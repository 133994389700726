import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  colors
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.indigo[600],
    height: 56,
    width: 56
  }
}));

const DashboardItem = ({name, count, link, Component}) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root)}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              {name}
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {count}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar} component={Link} to={link}>
              {Component}
            </Avatar>
          </Grid>
        </Grid>

      </CardContent>
    </Card>
  );
};

DashboardItem.propTypes = {
  name: PropTypes.string,
  count: PropTypes.number,
  Component: PropTypes.object
};

export default DashboardItem;