import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "./CustomButton";
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from "@material-ui/core/SvgIcon/SvgIcon";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import MUIDataTable from "mui-datatables";
import EditIcon from '@material-ui/icons/Edit';
import Chip from "@material-ui/core/Chip/Chip";
import firebase from "../../Firebase";
import backend from "../../helpers/backend";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '50px 50px',
    width: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  pay: {backgroundColor: '#00a152', color: 'white'}
}));

const ref = firebase.firestore().collection('expeditions');

const MiseAJourColis = (props) => {
  const classes = useStyles();

  const [state, setState] = useState({
    noResultSearch: 'Désolé colis introuvable',
    expeditions: [],
  });

  const columns = [
    {
      name: "Numero colis",
      options: {
        filter: true,
      }
    },
    {
      name: "Date Exp.",
      options: {
        filter: true,
      }
    },
    {
      name: "Adresse Exp.",
      options: {
        filter: true,
      }
    },
    {
      name: "Adresse Dest.",
      options: {
        filter: true,
      }
    },
    {
      name: "Tel Exp.",
      options: {
        filter: true,
      }
    },
    {
      name: "Tel Dest.",
      options: {
        filter: true,
      }
    },
    {
      name: "Statut.",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          let etat = value.split(".")[0];
          let pay = value.split(".")[1];
          let etatDisplay = etat === 'terminé' ? <Chip label={etat} color="primary"/> : etat === 'nouveau' ?
            <Chip label={etat} style={{backgroundColor: '#00a152', color: 'white'}}/> :
            <Chip label={etat} color="secondary"/>;
          let payDisplay = pay === '1' ?
            <Chip label="Payé" className={classes.pay} icon={<DoneAllIcon style={{color: 'white'}}/>}/> :
            <Chip label="Non payé" icon={<CloseIcon/>}/>;

          return <>{etatDisplay}{payDisplay}</>
        }
      }
    },
    {
      name: "Modifier",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <ButtonGroup size="small" color="primary" aria-label="small outlined button group">
              <Button onClick={() => goToItineraire(value)}><EditIcon/></Button>
            </ButtonGroup>
          );
        }
      }
    }
  ];

  const options = {
    responsive: 'standard',
    selectableRows: 'none',
    viewColumns: false,
    filter: false,
  };

  const goToItineraire = async (id) => {
    props.history.push("/itineraire/" + id);
  };

  const loadData = () => {
    let expeditions = [];
    ref.onSnapshot((querySnapshot) => {
      querySnapshot.forEach((document) => {
        let expedition = [];
        expedition.push(document.id);
        let pay = backend.checkPay(document);
        let dateExp = new Date(document.data().colis?.date);
        expedition.push(backend.myFormatDate(dateExp));
        expedition.push(document.data().sender.address);
        expedition.push(document.data().receiver.address);
        expedition.push(document.data().sender.phone);
        expedition.push(document.data().receiver.phone);
        expedition.push(document.data().etat + "." + pay);
        expedition.push(document.id);
        expeditions.push(expedition);
      });
      expeditions.sort(function (a, b) {
        return b[1].localeCompare(a[1]);
      });
      setState(state => ({...state, expeditions}));
    });
  };

  const render = () => {
    if (!state.expeditions.length) {
      return <div style={{position: 'absolute', top: '50%', left: '50%'}}>
        <CircularProgress/>
      </div>;
    } else {
      return <MUIDataTable data={state.expeditions} columns={columns} options={options}/>
    }
  };


  useEffect(() => {
    loadData();
    return () => {
    };
  }, []);

  return (
    <div className={classes.root}>
      <Box mb={3}>
        <Typography color="textPrimary" variant="h2">
          Colis
        </Typography>
        <Typography color="textSecondary" variant="body2">
          Mise a jour
        </Typography>
      </Box>
      {render()}
    </div>
  );
};

export default MiseAJourColis;
