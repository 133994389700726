import React, {useEffect, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import firebase from "../../Firebase";
import Container from "@material-ui/core/Container";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import Linkify from 'react-linkify';

const ref = firebase.firestore().collection('messages');
const storageRef = firebase.storage().ref();

const ShowMessage = (props) => {

  const [state, setState] = useState({
    message: {}
  });
  const [url, setUrl] = useState("");

  const getMessage = async () => {
    let message = await ref.doc(props.match.params.id).get();
    setState(state => ({...state, message: message.data()}));
    if(message.data().filename)
      getUrl(message.data().filename);
  };

  const getUrl = (path) => {
    storageRef.child(path).getDownloadURL()
      .then(url => setUrl(url));
  };

  useEffect(() => {
    getMessage();
    return () => {
    };
  }, []);


  return (
    <div className="root">
      <Box mb={3}>
        <Typography color="textPrimary" variant="h2">
          Details
        </Typography>
        <Typography color="textSecondary" variant="body2">
          Message du <strong>{state.message.date}</strong>
        </Typography>
      </Box>
      <Container>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" style={{fontWeight: 'bold'}} scope="row">
                  Nom
                </TableCell>
                <TableCell align="right" style={{width: 320}}> </TableCell>
                <TableCell align="justify">{state.message.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" style={{fontWeight: 'bold'}} scope="row">
                  Prenom
                </TableCell>
                <TableCell align="right" style={{width: 320}}> </TableCell>
                <TableCell align="justify">{state.message.firstname}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{fontWeight: 'bold'}} component="th" scope="row">
                  E-mail
                </TableCell>
                <TableCell align="right" style={{width: 320}}> </TableCell>
                <TableCell align="justify"><a
                  href={`mailto:${state.message.email}`}>{state.message.email}</a></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{fontWeight: 'bold'}} component="th" scope="row">
                  Telephone
                </TableCell>
                <TableCell align="right" style={{width: 320}}> </TableCell>
                <TableCell align="justify"><a href={`tel:${state.message.phone}`}>{state.message.phone}</a></TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{fontWeight: 'bold'}} component="th" scope="row">
                  Objet
                </TableCell>
                <TableCell align="right" style={{width: 320}}> </TableCell>
                <TableCell align="justify">{state.message.objet}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{fontWeight: 'bold'}} component="th" scope="row">
                  Message
                </TableCell>
                <TableCell align="right" style={{width: 320}}> </TableCell>
                <TableCell align="justify"><Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a target="blank" href={decoratedHref} key={key}>
                    {decoratedText}
                  </a>
                )}>{state.message.message}</Linkify></TableCell>
              </TableRow>
              {state.message.filename && <TableRow>
                <TableCell style={{fontWeight: 'bold'}} component="th" scope="row">
                  Pièce jointe
                </TableCell>
                <TableCell align="right" style={{width: 320}}> </TableCell>
                <TableCell align="justify">
                  <a href={url} target="_blank">
                    {state.message.filename}
                  </a>
                </TableCell>
              </TableRow>}
            </TableBody>
          </Table>
        </TableContainer>
        <Button style={{marginTop: '40px'}} variant="contained" color="primary"
                onClick={() => props.history.push('/messages')}>
          Retour
        </Button>
      </Container>
    </div>
  );
};

export default ShowMessage;
