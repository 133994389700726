import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box/Box";
import Typography from "@material-ui/core/Typography/Typography";
import {ConfirmProvider, useConfirm} from 'material-ui-confirm';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import MUIDataTable, {TableFilterList} from "mui-datatables";
import ButtonGroup from "@material-ui/core/ButtonGroup/ButtonGroup";
import Button from "@material-ui/core/Button/Button";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import firebase from "../../Firebase";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import Alert from "@material-ui/lab/Alert/Alert";
import Fab from "@material-ui/core/Fab";
import AddIcon from '@material-ui/icons/Add';


const ref = firebase.firestore().collection('admin-expedition-prices');

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '50px 50px',
    width: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  fw: {
    fontWeight: 'bold',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const TypeExpedition = (props) => {
  const confirm = useConfirm();

  const classes = useStyles();
  const [state, setState] = useState({
    info: null,
    typeExpeditions: [],
  });

  const columns = [
    {
      name: "Libellé",
      options: {
        filter: true,
      }
    },
    {
      name: "Description",
      options: {
        filter: true,
      }
    },
    {
      name: "Action",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <ButtonGroup size="small" color="primary" aria-label="small outlined button group">
              <Button onClick={() => goToDetail(value)}><VisibilityIcon/></Button>
              <Button color="secondary" onClick={() => deleteExpedition(value)}><DeleteIcon/></Button>
            </ButtonGroup>
          );
        }
      }
    }
  ];

  const options = {
    responsive: 'standard',
    selectableRows: 'none',
    viewColumns: false,
    filter: false,
  };

  const loadData = () => {
    let typeExpeditions = [];
    ref.get().then((querySnapshot) => {
      querySnapshot.forEach((document) => {
        let typeExpedition = [];
        typeExpedition.push(document.data().libelle);
        typeExpedition.push(document.data().description);
        typeExpedition.push(document.id);
        typeExpeditions.push(typeExpedition);
      });
      setState(state => ({...state, typeExpeditions}));
    });
  };

  useEffect(() => {
    loadData();
    return () => {
    };
  }, []);

  const render = () => {
    if (!state.typeExpeditions.length) {
      return <div style={{position: 'absolute', top: '50%', left: '50%'}}>
        <CircularProgress/>
      </div>;
    } else {
      return <MUIDataTable data={state.typeExpeditions} columns={columns} options={options}/>
    }
  };

  const clear = () => {
      setState(state => ({...state, info: null}));
  };

  const goToDetail = async (id) => {
    props.history.push("/show-type-expedition/" + id);
  };

  const deleteExpedition = (id) => {
    confirm({title: 'Etes vous sure ?', description: 'Voulez vous vraiment supprimer ?'})
      .then(() => {
        ref.doc(id).delete().then(() => {
          loadData();
          let info = "Expedition supprimé avec succès";
          setState(state => ({...state, info}));
        });
      })
      .catch(() => {
        console.log("sss")
      });
  };

  return (
    <div className={classes.root}>
      {state.info && <Snackbar open={Boolean(state.info)} autoHideDuration={4000} onClose={() => clear()}>
        <Alert onClose={() => clear()} variant="filled" severity="success">
          {state.info}
        </Alert>
      </Snackbar>}
      <Box mb={3}>
        <Typography color="textPrimary" variant="h2">
          Types d'expeditions
        </Typography>
        <Typography color="textSecondary" variant="body2">
          Gestion des types d'expeditions
        </Typography>
      </Box>
      {render()}
      <Fab color="primary" className={classes.fab} aria-label="add" onClick={() => props.history.push("/show-type-expedition/new")}>
        <AddIcon/>
      </Fab>
    </div>
  );
};

export default TypeExpedition;
