import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import clsx from "clsx";
import ChevronRightIcon from "@material-ui/core/SvgIcon/SvgIcon";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import Drawer from "@material-ui/core/Drawer/Drawer";
import {Link} from "react-router-dom";
import DashboardIcon from '@material-ui/icons/Dashboard';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import EuroIcon from '@material-ui/icons/Euro';
import SendIcon from '@material-ui/icons/Send';
import ForumIcon from '@material-ui/icons/Forum';
import RoomIcon from '@material-ui/icons/Room';
import MenuListItem from "./MenuListItem";

const AppDrawer = ({classes, theme, handleDrawerClose, open}) => {
    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
        >
            <div className={classes.toolbar}>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                </IconButton>
            </div>
            <Divider/>
            <List style={{
    fontSize: '11px'}}>
                <Link to="/" className={classes.link}>
                    <MenuListItem text="Tableau de bord" to="/" Component={<DashboardIcon/>}/>
                </Link>
                <Link to="/config" className={classes.link}>
                    <MenuListItem text="Configuration" to="/config" Component={<SettingsApplicationsIcon/>}/>
                </Link>
                <Link to="/oderconfig" className={classes.link}>
                    <MenuListItem text="Configuration Command" to="/oderconfig" Component={<SettingsIcon/>}/>
                </Link>
                <Link to="/messages" className={classes.link}>
                    <MenuListItem text="Messages" to="/messages" Component={<ForumIcon/>}/>
                </Link>
                <Link to="/mise-a-jour-colis" className={classes.link}>
                    <MenuListItem text="Mise à jour colis" to="/mise-a-jour-colis" Component={<AssignmentIcon/>}/>
                </Link>
                <Link to="/type-expeditions" className={classes.link}>
                    <MenuListItem text="Tarifs d'expéditions" to="/type-expeditions" Component={<EuroIcon/>}/>
                </Link>
            </List>
        </Drawer>
    );
};

export default AppDrawer;
