export default {
  myFormatDate: function (mydate) {
    let mois;
    let jour;
    let heure;
    let minute;
    let seconde;
    //format month
    if (mydate.getMonth() + 1 < 10)
      mois = "0" + (mydate.getMonth() + 1);
    else
      mois = mydate.getMonth() + 1;
    //format day
    if (mydate.getDate() < 10)
      jour = "0" + (mydate.getDate());
    else
      jour = mydate.getDate();
    //format hour
    if (mydate.getHours() < 10)
      heure = "0" + (mydate.getHours());
    else
      heure = mydate.getHours();
    //format minute
    if (mydate.getMinutes() < 10)
      minute = "0" + (mydate.getMinutes());
    else
      minute = mydate.getMinutes();
    //format seconde
    if (mydate.getSeconds() < 10)
      seconde = "0" + (mydate.getSeconds());
    else
      seconde = mydate.getSeconds();
    return mydate.getFullYear() + "/" + mois + "/" + jour + " " + heure + ":" + minute + ":" + seconde;
  },
  checkPay: function (document) {
    return document.data().hasOwnProperty('payment') === false || document.data().payment === false
      ? "0" : "1";
  }
}