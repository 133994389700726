import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import Box from "@material-ui/core/Box/Box";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import SaveIcon from '@material-ui/icons/Save';
import TextField from "@material-ui/core/TextField/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button/Button";
import firebase from "../../Firebase";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme) => ({
  root: {
    margin: '50px 50px',
    width: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
}));

const ref = firebase.firestore().collection('admin').doc("home");

const Config = () => {
  const classes = useStyles();
  const [state, setState] = useState({
    error: null,
    info: null,
    home: {},
  });

  useEffect(() => {
    start();
    return () => {
    };
  }, []);

  const handleChange = (event, field) => {
    setState(state => ({...state, home: {...state.home, [field]: event.target.value}}))
  };

  const start = () => {
    ref.get().then((doc) => {
      if (doc.exists) {
        let home = doc.data();
        setState(state => ({...state, home}));
      }
    });
  };

  const clear = () => {
    //setTimeout(() => {
      setState(state => ({...state, info: null, error: null}));
    //}, 4000);
  };

  const update = () => {
    ref.set(state.home).then(() => {
      let info = "Enregistré avec succès";
      setState(state => ({...state, info}));
      return true;
    }).catch((error) => {
      setState(state => ({...state, error}));
    });
  };


  return (
    <div className={classes.root}>
      {state.info && <Snackbar key={Math.random()} open={Boolean(state.info)} autoHideDuration={6000} onClose={clear}>
        <Alert onClose={clear} variant="filled" severity="success">
          {state.info}
        </Alert>
      </Snackbar>}
      {state.error && <Snackbar open={Boolean(state.error)} autoHideDuration={10000} onClose={clear}>
        <Alert onClose={clear} variant="filled" severity="error">
          {state.error}
        </Alert>
      </Snackbar>}
      <Box mb={3}>
        <Typography color="textPrimary" variant="h2">
          Banniere
        </Typography>
        <Typography color="textSecondary" gutterBottom variant="body2">
          Configuration
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="outlined-full-width"
            label="Titre 1"
            style={{margin: 8}}
            value={state.home.title1 || ''}
            onChange={(event) => handleChange(event, 'title1')}
            placeholder="Titre 1"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
          <TextField
            id="outlined-full-width"
            label="Description 1"
            value={state.home.desc1 || ''}
            style={{margin: 8}}
            onChange={(event) => handleChange(event, 'desc1')}
            placeholder="Description 1"
            fullWidth
            multiline
            rows={4}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-full-width"
            label="Titre 2"
            value={state.home.title2 || ''}
            onChange={(event) => handleChange(event, 'title2')}
            style={{margin: 8}}
            placeholder="Titre 2"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
          <TextField
            id="outlined-full-width"
            label="Description 2"
            value={state.home.desc2 || ''}
            style={{margin: 8}}
            onChange={(event) => handleChange(event, 'desc2')}
            placeholder="Description 2"
            fullWidth
            multiline
            rows={4}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="outlined-full-width"
            label="Titre 3"
            value={state.home.title3 || ''}
            onChange={(event) => handleChange(event, 'title3')}
            style={{margin: 8}}
            placeholder="Titre 3"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
          <TextField
            id="outlined-full-width"
            label="Description 3"
            value={state.home.desc3 || ''}
            style={{margin: 8}}
            onChange={(event) => handleChange(event, 'desc3')}
            placeholder="Description 3"
            fullWidth
            multiline
            rows={4}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Button
        variant="contained"
        style={{backgroundColor: 'green', color: 'white'}}
        size="large"
        onClick={() => update()}
        startIcon={<SaveIcon/>}
      >
        Modifier
      </Button>
    </div>
  );
};

export default Config;
