import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid/Grid";
import ForumIcon from '@material-ui/icons/Forum';
import {makeStyles} from '@material-ui/core/styles';
import DashboardItem from "./DashboardItem";
import EuroIcon from '@material-ui/icons/Euro';
import SendIcon from '@material-ui/icons/Send';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import firebase from "../../Firebase";
import backend from "../../helpers/backend";
import DashboardItemPromo from "./DashboardItemPromo";

// Firebase references
const ref = {
  MsgNew: firebase.firestore().collection('messages').where('lu', '==', 0),
  ExpNew: firebase.firestore().collection('expeditions').where('etat', '==', 'nouveau'),
  ExpRun: firebase.firestore().collection('expeditions').where('etat', '==', 'encours'),
  Price: firebase.firestore().collection('admin').doc("prices").collection('lists'),
};


const Dashboard = () => {
  const classes = useStyles();

  const [state, setState] = useState({
    messageNonLu: 0,
    newExpeditions: 0,
    expeditionEncours: 0,
    nextPromo: {},
  });

  useEffect(() => {
    start();
    return () => {

    };
  }, []);

  const start = () => {
    //get new expeditions requests.
    ref.ExpNew.onSnapshot((querySnapshot) => {
      let newExpeditions = querySnapshot.docs.length;
      setState(state => ({...state, newExpeditions}));
    });

    //get unread messages count
    ref.MsgNew.onSnapshot((querySnapshot) => {
      let messageNonLu = querySnapshot.docs.length;
      setState(state => ({...state, messageNonLu}));
    });

    //get count of ongoing expeditions.
    ref.ExpRun.onSnapshot((querySnapshot) => {
      let expeditionEncours = querySnapshot.docs.length;
      setState(state => ({...state, expeditionEncours}));
    });

    //get next promotion information
    ref.Price.onSnapshot((querySnapshot) => {
      let nextPromo = {};
      let todayDate = new Date();
      let refDate = todayDate.setDate(todayDate.getDate() + 1);
      todayDate = new Date();
      querySnapshot.forEach((document) => {
        let data = document.data();
        let curPromoDate = new Date(data.dateD);
        if (data.type === "promo" && curPromoDate > todayDate) {
          if (curPromoDate <= refDate) {
            let dateDebut = new Date(data.dateD);
            nextPromo.dateDebutPromo = backend.myFormatDate(dateDebut);
            let dateFin = new Date(data.dateF);
            nextPromo.dateFinPromo = backend.myFormatDate(dateFin);
            nextPromo.prixPoid = data.prixRefPoids;
            nextPromo.prixVolume = data.prixRefVol;
            refDate = curPromoDate;
          }
        }
      });
      console.log(nextPromo);
      setState(state => ({...state, nextPromo}));
    });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DashboardItem count={state.messageNonLu} name={"Nouveau message"} link={"messages"} Component={<ForumIcon/>}/>
        </Grid>
        <Grid item xs={6}>
          <DashboardItem count={state.newExpeditions} name={"Nouvelle expedition"} link={"mise-a-jour-colis"} Component={<SendIcon/>}/>
        </Grid>
        <Grid item xs={6}>
          <DashboardItem count={state.expeditionEncours} name={"En cour de livraison"} link={"mise-a-jour-colis"} Component={<ShoppingCartIcon/>}/>
        </Grid>
        <Grid item xs={6}>
          <DashboardItemPromo nextPromo={state.nextPromo} name={"Prochaine promotion"} link={"type-expeditions"} Component={<EuroIcon/>}/>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: '100px 100px 0'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    width: '400px',
  },
}));

export default Dashboard;
